import {Component, OnInit} from '@angular/core';

import {NzTabPosition} from 'ng-zorro-antd/tabs';

import {CategoryInterface, ProjectInterface} from '../../data/interface';

import {Projects} from '../../data/projects';
import {Categories} from '../../data/categories';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  nzTabPosition: NzTabPosition = 'left';

  public categories: CategoryInterface[] = Categories;
  public projects: ProjectInterface[] = Projects;

  constructor() {
  }

  ngOnInit(): void {
    console.log(this.projects);
  }
}
