import {CategoryInterface} from './interface';

export const Categories: CategoryInterface[] = [
  {
    category_id: 1000,
    category_name: 'Commercial'
  },
  {
    category_id: 1001,
    category_name: 'Office'
  },
  {
    category_id: 1002,
    category_name: 'Residential'
  }
];
