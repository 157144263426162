import {Pipe, PipeTransform} from '@angular/core';
import {ProjectInterface} from '../interface';

@Pipe({
  name: 'filterProject'
})
export class FilterProjectPipe implements PipeTransform {

  transform(projects: ProjectInterface[], categoryId: number): ProjectInterface[] {
    return projects.filter(e => e.project_category === categoryId) || [];
  }

}
