import {NgModule} from '@angular/core';

import {NzGridModule} from 'ng-zorro-antd/grid';
import {NzPageHeaderModule} from 'ng-zorro-antd/page-header';
import {NzAffixModule} from 'ng-zorro-antd/affix';
import {NzTabsModule} from 'ng-zorro-antd/tabs';
import {NzBreadCrumbModule} from 'ng-zorro-antd/breadcrumb';
import {NzCarouselModule} from 'ng-zorro-antd/carousel';
import {NzIconModule} from 'ng-zorro-antd/icon';

@NgModule({
  exports: [
    NzGridModule,
    NzPageHeaderModule,
    NzAffixModule,
    NzTabsModule,
    NzBreadCrumbModule,
    NzCarouselModule,
    NzIconModule
  ],
  providers: []
})

export class NgZorroSharedModule {
}
