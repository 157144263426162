<app-header></app-header>

<div class="container-fluid">
  <div class="row">
    <div class="col-md-12 p-0">
      <div id="carouselExampleCaptions" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-indicators">
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
          <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
        </div>
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img src="../../../assets/projects/Dr Ko QBM/4.jpg" class="d-block w-100">
          </div>
          <div class="carousel-item">
            <img src="../../../assets/projects/Inanna/1.jpg" class="d-block w-100">
          </div>
        </div>

        <div class="carousel-caption">
          <h1>We do the right things in the right way</h1>
          <h5>We are a collection of real designers, we draw and we know materials.</h5>
          <button class="general-btn mt-4" routerLink="/portfolio">VIEW PORTFOLIO</button>
        </div>
      </div>
    </div>
  </div>

  <div id="section2" class="row content-div">
    <div class="col-md-12 text-center">
      <h1>Your first and best choice for construction and remodeling projects</h1>
      <h5>TMUSE Design Interior Design company specializes in both residential and commercial works. Our team of seasoned designers and contractors are here to partner you through your project from the very beginning to the very end.</h5>
    </div>
  </div>

  <div id="section3" class="row" routerLink="/portfolio">
    <div class="col-md-6 p-0">
      <img src="../../../assets/projects/Inanna/1.jpg">
      <div class="overlay">
        <div class="inner">
          <h3>Clinic Inanna Bangsar</h3>
          <h6>Commercial</h6>
        </div>
      </div>
    </div>

    <div class="col-md-3 p-0">
      <img src="../../../assets/projects/Dr Ko/4.jpg">
      <div class="overlay">
        <div class="inner">
          <h3>Dr Ko</h3>
          <h6>Commercial</h6>
        </div>
      </div>
    </div>

    <div class="col-md-3 p-0">
      <img src="../../../assets/projects/EXA/1.jpg">
      <div class="overlay">
        <div class="inner">
          <h3>EXA</h3>
          <h6>Commercial</h6>
        </div>
      </div>
    </div>

    <div class="col-md-6 p-0">
      <img src="../../../assets/projects/Dr Ko QBM/1.jpg">
      <div class="overlay">
        <div class="inner">
          <h3>Dr Ko Clinic Queens Bay Mall</h3>
          <h6>Commercial</h6>
        </div>
      </div>
    </div>

    <div class="col-md-3 p-0">
      <img src="../../../assets/projects/Ong Yoke Lin/4.jpg">
      <div class="overlay">
        <div class="inner">
          <h3>Ong Yoke Lin Sdn Bhd</h3>
          <h6>Office</h6>
        </div>
      </div>
    </div>

    <div class="col-md-3 p-0">
      <img src="../../../assets/projects/EK/1.jpg">
      <div class="overlay">
        <div class="inner">
          <h3>EK</h3>
          <h6>Residential</h6>
        </div>
      </div>
    </div>
  </div>

  <div id="section4" class="row content-div">
    <div class="col-md-12 text-center">
      <h2 class="text-b">About TMuse</h2>
      <h6>
        Although the profession of interior design has only been around for a little over a century, the process of designing an interior space has been practiced for as long as humans have been building homes and decorating them according to their preferences and the resources that are available to them.
        <br><br>
        TMUSE Design provides clients of various tastes with a comprehensive design service, resulting in the creation of elegant residential houses and commercial spaces. We have a deep-seated conviction that high-quality interior design improves people’s lives and we strive to implement this philosophy in every project that we take on. Each project is meticulously carried out and finished in an aesthetically pleasing manner.
      </h6>
    </div>

    <div class="col-md-4 text-center">
      <img src="../../../assets/projects/Dr Ko QBM/4.jpg">
<!--      <h4 class="mt-4">Lorem Ipsum</h4>-->
<!--      <p>Proin sagittis sem ac molestie tempor. Nunc volutpat est nisi, eget egestas ante egestas efficitur.</p>-->
    </div>

    <div class="col-md-4 text-center">
      <img src="../../../assets/projects/Inanna/4.jpg">
<!--      <h4 class="mt-4">Lorem Ipsum</h4>-->
<!--      <p>Maecenas et libero quis mauris posuere bibendum sed in enim. In quis hendrerit tellus, luctus vestibulum tellus.</p>-->
    </div>

    <div class="col-md-4 text-center">
      <img src="../../../assets/projects/Klang/1.jpg">
<!--      <h4 class="mt-4">Lorem Ipsum</h4>-->
<!--      <p>Suspendisse auctor sodales dictum. Curabitur mauris ante, interdum at risus a, imperdiet venenatis justo.</p>-->
    </div>
  </div>

  <div id="section5" class="row content-div">
    <div class="col-md-12 text-center">
      <h2 class="text-b">Contact Us</h2>
      <h6>Feel free to reach out to us for any enquiries, we'd love to hear from you!</h6>
    </div>

    <div id="details" class="col-md-12">
      <div class="row">
        <div class="col-md-4 text-center">
          <i class="material-icons-outlined">near_me</i>
          <h4>Our Office</h4>
          <p>
            401, Block A, Plaza Damansara,<br>
            Bukit Damansara, 50490 Kuala Lumpur
          </p>
          <hr>
        </div>

        <div class="col-md-4 text-center">
          <i class="material-icons-outlined">phone</i>
          <h4>Drop Us A Call</h4>
          <p>012-233 7437</p>
          <hr>
        </div>

        <div class="col-md-4 text-center">
          <i class="material-icons-outlined">email</i>
          <h4>Email Us</h4>
          <p>admin@tmusedesign.com</p>
          <hr>
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
