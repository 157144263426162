<div class="container-fluid">
  <div id="top-footer" class="row content-div">
    <div class="col-md-4">
      <h4>TMuse Design</h4>
      <p>Your first and best choice for construction and remodeling projects, TMUSE Design Interior Design company specializes in both residential and commercial works. Our team of seasoned designers and contractors are here to partner you through your project from the very beginning to the very end.</p>
    </div>

    <div class="col-md-1"></div>

    <div class="col-md-2">
      <h5>Sitemap</h5>
      <ul>
        <li><a routerLink="/">Home</a></li>
        <li><a routerLink="/portfolio">Portfolio</a></li>
      </ul>
    </div>

    <div class="col-md-2">
      <h5>Legal</h5>
      <ul>
        <li>Privacy Policy</li>
        <li>Terms and Conditions</li>
      </ul>
    </div>

    <div class="col-md-3">
      <h5>Contact Us</h5>
      <ul>
        <li class="mb-2">
          401, Block A, Plaza Damansara,<br>
          Bukit Damansara, 50490 Kuala Lumpur
        </li>
        <li>Tel: 012-233 7437</li>
        <li>Email: admin@tmusedesign.com</li>
      </ul>
    </div>
  </div>

  <div id="copyright" class="row content-div">
    <div class="col-md-12 text-center">
      <p>© {{ year }} TMuse Design. All Rights Reserved.</p>
    </div>
  </div>
</div>
