import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing-module';
import {SharedModule} from './shared/components/shared.module';
import {NgZorroSharedModule} from './ng-zorro-shared.module';

import {HomeComponent} from './pages/home/home.component';
import {PortfolioComponent} from './pages/portfolio/portfolio.component';
import {DetailsComponent} from './pages/details/details.component';

import {CategoryPipe} from './data/pipe/category.pipe';
import {FilterProjectPipe} from './data/pipe/filter-product.pipe';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PortfolioComponent,
    DetailsComponent,
    CategoryPipe,
    FilterProjectPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    NgZorroSharedModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
