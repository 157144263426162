<app-header></app-header>

<div nz-row class="content-div">
  <div nz-col nzSpan="24">
    <nz-tabset [nzTabPosition]="nzTabPosition">

      <nz-tab nzTitle="All">
        <div nz-row>
          <div routerLink="/details" nz-col nzSpan="8" class="newsCard news-Slide-up" *ngFor="let project of projects" [queryParams]="{project_id: project.project_id}">
            <!-- Get the first project_image -->
            <img [src]="project.project_images[0]">
            <div class='newsCaption d-flex flex-column justify-content-center'>
              <!-- Project Name -->
              <h4>{{ project.project_name }}</h4>
              <!-- Category Name -->
              <h6>{{ project.project_category | category }}</h6>
            </div>
          </div>
        </div>
      </nz-tab>

      <nz-tab *ngFor="let category of categories" [nzTitle]="category.category_name">
        <div nz-row>
          <div routerLink="/details" nz-col nzSpan="8" class="newsCard news-Slide-up" *ngFor="let project of projects | filterProject : category.category_id " [queryParams]="{project_id: project.project_id}">
            <!-- Get the first project_image -->
            <img [src]="project.project_images[0]">
            <div class='newsCaption d-flex flex-column justify-content-center'>
              <!-- Project Name -->
              <h4>{{ project.project_name }}</h4>
              <!-- Category Name -->
              <h6>{{ project.project_category | category }}</h6>
            </div>
          </div>
        </div>
      </nz-tab>

      <!--<nz-tab nzTitle="All">-->
      <!--  <div nz-row>-->

      <!--    &lt;!&ndash; For loop the projects &ndash;&gt;-->


      <!--    &lt;!&ndash;<div routerLink="/details" nz-col nzSpan="8" class="newsCard news-Slide-up">&ndash;&gt;-->
      <!--    &lt;!&ndash;  <img src="../../../assets/images/sample-1.jpg">&ndash;&gt;-->
      <!--    &lt;!&ndash;  <div class='newsCaption d-flex flex-column justify-content-center'>&ndash;&gt;-->
      <!--    &lt;!&ndash;    <h4>Church Reception Area</h4>&ndash;&gt;-->
      <!--    &lt;!&ndash;    <h6>Commercial</h6>&ndash;&gt;-->
      <!--    &lt;!&ndash;  </div>&ndash;&gt;-->
      <!--    &lt;!&ndash;</div>&ndash;&gt;-->


      <!--    &lt;!&ndash;<div nz-col nzSpan="8" class="newsCard news-Slide-up">&ndash;&gt;-->
      <!--    &lt;!&ndash;  <img src="../../../assets/images/sample-2.jpg">&ndash;&gt;-->
      <!--    &lt;!&ndash;  <div class='newsCaption d-flex flex-column justify-content-center'>&ndash;&gt;-->
      <!--    &lt;!&ndash;    <h4>Dr Ko 3D Visual</h4>&ndash;&gt;-->
      <!--    &lt;!&ndash;    <h6>Commercial</h6>&ndash;&gt;-->
      <!--    &lt;!&ndash;  </div>&ndash;&gt;-->
      <!--    &lt;!&ndash;</div>&ndash;&gt;-->


      <!--    &lt;!&ndash;<div nz-col nzSpan="8" class="newsCard news-Slide-up">&ndash;&gt;-->
      <!--    &lt;!&ndash;  <img src="../../../assets/images/sample-3.jpg">&ndash;&gt;-->
      <!--    &lt;!&ndash;  <div class='newsCaption d-flex flex-column justify-content-center'>&ndash;&gt;-->
      <!--    &lt;!&ndash;    <h4>EXA</h4>&ndash;&gt;-->
      <!--    &lt;!&ndash;    <h6>Commercial</h6>&ndash;&gt;-->
      <!--    &lt;!&ndash;  </div>&ndash;&gt;-->
      <!--    &lt;!&ndash;</div>&ndash;&gt;-->

      <!--  </div>-->
      <!--</nz-tab>-->


      <!--<nz-tab nzTitle="Commercial">Content of Tab Pane 2</nz-tab>-->
      <!--<nz-tab nzTitle="Office">Content of Tab Pane 3</nz-tab>-->
    </nz-tabset>
  </div>
</div>

<app-footer></app-footer>
