import {Pipe, PipeTransform} from '@angular/core';

import {CategoryInterface} from '../interface';
import {Categories} from '../categories';

@Pipe({
  name: 'category'
})
export class CategoryPipe implements PipeTransform {

  public categories: CategoryInterface[] = Categories;

  transform(value: number, ...args: unknown[]): unknown {
    const find = this.categories.find(e => e.category_id === value);
    if (find) {
      return find.category_name;
    }
    return null;
  }

}
