import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgZorroSharedModule} from '../../ng-zorro-shared.module';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroSharedModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ]
})

export class SharedModule {}
