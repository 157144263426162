<app-header></app-header>

<div class="container-fluid">
  <div class="row content-div">
    <div class="col-md-6">
      <h6>
        {{ project_info.project_category | category }}
      </h6>
      <h3>
        {{ project_info.project_name }}
      </h3>
    </div>
    <div class="col-md-6 d-flex justify-content-end">
      <nz-breadcrumb>
        <nz-breadcrumb-item>
          <a routerLink="/">Home</a>
        </nz-breadcrumb-item>
        <nz-breadcrumb-item>
          <a routerLink="/portfolio">Portfolio</a>
        </nz-breadcrumb-item>
        <nz-breadcrumb-item>
          {{ project_info.project_name }}
        </nz-breadcrumb-item>
      </nz-breadcrumb>
    </div>
  </div>

  <div class="row content-div pt-0">
    <div class="col-md-8 pe-4">
      <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
          <div *ngFor="let image of project_info.project_images; let first=first;" [ngClass]="{'active': first}" class="carousel-item">
            <img [src]="image" class="d-block w-100">
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Next</span>
        </button>
      </div>
    </div>

    <div class="col-md-4">
      <div class="details">
        <h3>About The Project</h3>
        <p [innerHtml]="project_info.project_desc"></p>
      </div>

      <div class="details" *ngIf="project_info.project_client">
        <h4>Client</h4>
        <p>{{ project_info.project_client }}</p>
      </div>

      <div class="details">
        <h4>Date</h4>
        <p>{{ project_info.project_date }}</p>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
