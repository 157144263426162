import {ProjectInterface} from './interface';

export const Projects: ProjectInterface[] = [
  {
    project_id: 10000,
    project_name: 'Dr Ko Clinic Queens Bay Mall',
    project_desc: '',
    project_client: 'Dr Ko Pharmaceutical',
    project_date: 'October 25, 2022',
    project_date_in_unix: 1659673717,
    project_images: [
      '../../assets/projects/Dr Ko QBM/1.jpg',
      '../../assets/projects/Dr Ko QBM/2.jpg',
      '../../assets/projects/Dr Ko QBM/3.jpg',
      '../../assets/projects/Dr Ko QBM/4.jpg',
      '../../assets/projects/Dr Ko QBM/5.jpg',
      '../../assets/projects/Dr Ko QBM/6.jpg',
      '../../assets/projects/Dr Ko QBM/7.jpg',
      '../../assets/projects/Dr Ko QBM/8.jpg',
      '../../assets/projects/Dr Ko QBM/9.jpg',
      '../../assets/projects/Dr Ko QBM/10.jpg',
      '../../assets/projects/Dr Ko QBM/11.jpg',
      '../../assets/projects/Dr Ko QBM/12.jpg',
      '../../assets/projects/Dr Ko QBM/13.jpg',
      '../../assets/projects/Dr Ko QBM/14.jpg',
      '../../assets/projects/Dr Ko QBM/15.jpg',
      '../../assets/projects/Dr Ko QBM/16.jpg',
      '../../assets/projects/Dr Ko QBM/17.jpg',
      '../../assets/projects/Dr Ko QBM/18.jpg',
      '../../assets/projects/Dr Ko QBM/19.jpg',
      '../../assets/projects/Dr Ko QBM/20.jpg',
    ],
    project_category: 1000,
    project_status: 1
  },
  {
    project_id: 10001,
    project_name: 'Clinic Inanna Bangsar',
    project_desc: '',
    project_client: 'Dr Ko Pharmaceutical',
    project_date: 'October 5, 2022',
    project_date_in_unix: 1659673717,
    project_images: [
      '../../assets/projects/Inanna/1.jpg',
      '../../assets/projects/Inanna/2.jpg',
      '../../assets/projects/Inanna/3.jpg',
      '../../assets/projects/Inanna/4.jpg',
      '../../assets/projects/Inanna/5.jpg',
      '../../assets/projects/Inanna/6.jpg',
      '../../assets/projects/Inanna/7.jpg',
      '../../assets/projects/Inanna/8.jpg',
      '../../assets/projects/Inanna/9.jpg',
      '../../assets/projects/Inanna/10.jpg',
      '../../assets/projects/Inanna/11.jpg',
      '../../assets/projects/Inanna/12.jpg',
      '../../assets/projects/Inanna/13.jpg',
      '../../assets/projects/Inanna/14.jpg',
      '../../assets/projects/Inanna/15.jpg',
      '../../assets/projects/Inanna/16.jpg',
      '../../assets/projects/Inanna/17.jpg',
      '../../assets/projects/Inanna/18.jpg',
      '../../assets/projects/Inanna/19.jpg',
      '../../assets/projects/Inanna/20.jpg',
      '../../assets/projects/Inanna/21.jpg',
      '../../assets/projects/Inanna/22.jpg',
      '../../assets/projects/Inanna/23.jpg',
      '../../assets/projects/Inanna/24.jpg',
      '../../assets/projects/Inanna/25.jpg',
      '../../assets/projects/Inanna/26.jpg',
      '../../assets/projects/Inanna/27.jpg',
      '../../assets/projects/Inanna/28.jpg',
      '../../assets/projects/Inanna/29.jpg',
      '../../assets/projects/Inanna/30.jpg',
      '../../assets/projects/Inanna/31.jpg',
      '../../assets/projects/Inanna/32.jpg',
      '../../assets/projects/Inanna/33.jpg',
      '../../assets/projects/Inanna/34.jpg',
      '../../assets/projects/Inanna/35.jpg',
      '../../assets/projects/Inanna/36.jpg',
      '../../assets/projects/Inanna/37.jpg',
      '../../assets/projects/Inanna/38.jpg',
      '../../assets/projects/Inanna/39.jpg',
      '../../assets/projects/Inanna/40.jpg',
      '../../assets/projects/Inanna/41.jpg',
      '../../assets/projects/Inanna/42.jpg',
      '../../assets/projects/Inanna/43.jpg'
    ],
    project_category: 1000,
    project_status: 1
  },
  // {
  //   project_id: 10002,
  //   project_name: 'Church',
  //   project_desc: 'Church reception area & staircase',
  //   project_date: 'August 5, 2022',
  //   project_date_in_unix: 1659673717,
  //   project_images: [
  //     '../../assets/projects/Church/1.jpg',
  //     '../../assets/projects/Church/2.jpg'
  //   ],
  //   project_category: 1000,
  //   project_status: 1
  // },
  {
    project_id: 10003,
    project_name: 'Dr Ko',
    project_desc: 'Design & Build',
    project_client: 'Dr Ko Sunway Pyramid',
    project_date: 'August 5, 2022',
    project_date_in_unix: 1659673717,
    project_images: [
      '../../assets/projects/Dr Ko/1.jpg',
      '../../assets/projects/Dr Ko/2.jpg',
      '../../assets/projects/Dr Ko/3.jpg',
      '../../assets/projects/Dr Ko/4.jpg',
      '../../assets/projects/Dr Ko/5.jpg'
    ],
    project_category: 1000,
    project_status: 1
  },
  {
    project_id: 10004,
    project_name: 'EXA',
    project_desc: '',
    project_client: '',
    project_date: 'August 5, 2022',
    project_date_in_unix: 1659673717,
    project_images: [
      '../../assets/projects/EXA/1.jpg',
      '../../assets/projects/EXA/2.jpg',
      '../../assets/projects/EXA/3.jpg'
    ],
    project_category: 1000,
    project_status: 1
  },
  {
    project_id: 10005,
    project_name: 'G9 Hotel',
    project_desc: 'G9 hotel room, Type B',
    project_client: '',
    project_date: 'August 5, 2022',
    project_date_in_unix: 1659673717,
    project_images: [
      '../../assets/projects/G9/1.jpg',
    ],
    project_category: 1000,
    project_status: 1
  },
  // {
  //   project_id: 10006,
  //   project_name: 'Qi Bakery',
  //   project_desc: '',
  //   project_date: 'August 5, 2022',
  //   project_date_in_unix: 1659673717,
  //   project_images: [
  //     '../../assets/projects/Qi/1.jpg',
  //     '../../assets/projects/Qi/2.jpg'
  //   ],
  //   project_category: 1000,
  //   project_status: 1
  // },
  {
    project_id: 10007,
    project_name: 'Ong Yoke Lin Sdn Bhd',
    project_desc:
      '<ul>' +
      '<li>Reception Area</li>' +
      '<li>Ground Floor - Workstations Area</li>' +
      '<li>First Floor - Discussion Area</li>' +
      '<li>First Floor - Workstations Area</li>' +
      '<li>General Manager Room</li>' +
      '<li>CEO Room</li>' +
      '<li>Second Floor - Lift Area</li>' +
      '<li>Pantry</li>' +
      '</ul>',
    project_client: '',
    project_date: 'August 5, 2022',
    project_date_in_unix: 1659673717,
    project_images: [
      '../../assets/projects/Ong Yoke Lin/1.jpg',
      '../../assets/projects/Ong Yoke Lin/2.jpg',
      '../../assets/projects/Ong Yoke Lin/3.jpg',
      '../../assets/projects/Ong Yoke Lin/4.jpg',
      '../../assets/projects/Ong Yoke Lin/5.jpg',
      '../../assets/projects/Ong Yoke Lin/6.jpg',
      '../../assets/projects/Ong Yoke Lin/7.jpg',
      '../../assets/projects/Ong Yoke Lin/8.jpg'
    ],
    project_category: 1001,
    project_status: 1
  },
  {
    project_id: 10008,
    project_name: 'The Airie',
    project_desc: 'Foyer area',
    project_client: '',
    project_date: 'August 5, 2022',
    project_date_in_unix: 1659673717,
    project_images: [
      '../../assets/projects/Airie/1.jpg'
    ],
    project_category: 1001,
    project_status: 1
  },
  {
    project_id: 10009,
    project_name: 'APIB',
    project_desc: '',
    project_client: '',
    project_date: 'August 5, 2022',
    project_date_in_unix: 1659673717,
    project_images: [
      '../../assets/projects/APIB/1.jpg'
    ],
    project_category: 1001,
    project_status: 1
  },
  {
    project_id: 10010,
    project_name: 'Klang',
    project_desc:
      '<ul>' +
      '<li>Living Room</li>' +
      '<li>Foyer</li>' +
      '<li>Living Area</li>' +
      '<li>Study Area</li>' +
      '<li>Dining Area</li>' +
      '<li>Dining - Tan</li>' +
      '<li>Master Bedroom</li>' +
      '<li>Bedroom</li>' +
      '<li>Guest Room 1C</li>' +
      '<li>Guest Room 2C</li>' +
      '<li>Wine - Tan</li>' +
      '</ul>',
    project_client: '',
    project_date: 'August 5, 2022',
    project_date_in_unix: 1659673717,
    project_images: [
      '../../assets/projects/Klang/1.jpg',
      '../../assets/projects/Klang/2.jpg',
      '../../assets/projects/Klang/3.jpg',
      '../../assets/projects/Klang/4.jpg',
      '../../assets/projects/Klang/5.jpg',
      '../../assets/projects/Klang/6.jpg',
      '../../assets/projects/Klang/7.jpg',
      '../../assets/projects/Klang/8.jpg',
      '../../assets/projects/Klang/9.jpg',
      '../../assets/projects/Klang/10.jpg',
      '../../assets/projects/Klang/11.jpg'
    ],
    project_category: 1002,
    project_status: 1
  },
  {
    project_id: 100011,
    project_name: 'Sungai Buloh',
    project_desc: 'Living room & kitchen',
    project_client: '',
    project_date: 'August 5, 2022',
    project_date_in_unix: 1659673717,
    project_images: [
      '../../assets/projects/Sg Buloh/1.jpg',
      '../../assets/projects/Sg Buloh/2.jpg'
    ],
    project_category: 1002,
    project_status: 1
  },
  {
    project_id: 10012,
    project_name: 'EK',
    project_desc: 'Living room & master bedroom',
    project_client: '',
    project_date: 'August 5, 2022',
    project_date_in_unix: 1659673717,
    project_images: [
      '../../assets/projects/EK/1.jpg',
      '../../assets/projects/EK/2.jpg'
    ],
    project_category: 1002,
    project_status: 1
  },
];

