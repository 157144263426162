import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {Projects} from '../../data/projects';
import {ProjectInterface} from '../../data/interface';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit, OnDestroy {

  public project_id: number = null;
  public project_info: ProjectInterface = null;
  private subs: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute
  ) {

  }

  ngOnInit(): void {
    this.initPage();
  }

  ngOnDestroy() {
    this.subs.forEach(s => {
      if (s) {
        s.unsubscribe();
      }
    });
  }

  private initPage() {
    this.subs.push(
      this.activatedRoute.queryParamMap
        .subscribe(
          (queryParamMap) => {
            this.project_id = +queryParamMap.get('project_id');
            this.project_info = Projects.find(e => e.project_id === this.project_id);
            console.log(this.project_info);
          }
        )
    );
  }

}
